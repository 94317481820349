import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Box, Section, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наші послуги | Спектр послуг автосервісу Code Xafly
			</title>
			<meta name={"description"} content={"Прикрасьте свій автомобіль - відкрийте для себе блискучий спектр автомобільних деталей у Code Xafly\n"} />
			<meta property={"og:title"} content={"Наші послуги | Спектр послуг автосервісу Code Xafly"} />
			<meta property={"og:description"} content={"Прикрасьте свій автомобіль - відкрийте для себе блискучий спектр автомобільних деталей у Code Xafly\n"} />
			<meta property={"og:image"} content={"https://ua.codexafly.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ua.codexafly.com/img/451123-0450-31.jpeg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ua.codexafly.com/img/451123-0450-31.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ua.codexafly.com/img/451123-0450-31.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ua.codexafly.com/img/451123-0450-31.jpeg"} />
			<link rel={"apple-touch-startup-image"} href={"https://ua.codexafly.com/img/451123-0450-31.jpeg"} />
			<meta name={"msapplication-TileImage"} content={"https://ua.codexafly.com/img/451123-0450-31.jpeg"} />
		</Helmet>
		<Components.Header />
		<Section background="url(https://ua.codexafly.com/img/1.jpg) center/cover" padding="140px 0" sm-padding="40px 0" quarkly-title="About-16">
			<Override slot="SectionContent" align-items="center" justify-content="center" sm-min-width="280px" />
			<Box
				padding="50px 80px 50px 50px"
				color="--dark"
				display="flex"
				flex-direction="column"
				align-items="center"
				max-width="800px"
				background="#ffffff"
				sm-padding="50px 24px 50px 24px"
			>
				<Text margin="0 0 16px 0" as="h1" font="--headline2">
					Наші послуги
				</Text>
				<Hr
					min-height="10px"
					margin="0px 0px 0px 0px"
					border-color="--color-dark"
					border-width="3px 0 0 0"
					max-width="50px"
					width="100%"
				/>
				<Text font="--base" text-align="center">
					У автосервісі Code Xafly Auto Detailing ми виходимо за рамки звичайної автомийки, пропонуючи широкий спектр послуг, які оживляють і підкреслюють справжню сутність вашого автомобіля. Від ретельної обробки екстер'єру до розкішної обробки салону, Code Xafly - це ваш шлях до автомобільної елегантності.
				</Text>
			</Box>
		</Section>
		<Section background="#ffffff" padding="90px 0 110px 0" quarkly-title="Team-12">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				flex-direction="column"
				lg-width="100%"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="repeat(2, 1fr)"
				grid-gap="46px 24px"
				sm-grid-template-columns="1fr"
				sm-grid-template-rows="auto"
				sm-grid-gap="36px 0"
				margin="50px 0px 0px 0px"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
			>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://ua.codexafly.com/img/3.jpg"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="250px"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
							Основні
						</Text>
						<Text margin="8px 0px 0px 0px" color="--greyD2" font="300 18px/140% --fontFamily-sansHelvetica">
							- Корекція фарби: Попрощайтеся з вибоїнами та подряпинами з нашими професійними послугами з корекції фарби, відновивши лакофарбове покриття вашого автомобіля до первісного блиску.
							<br />
							- Керамічне покриття: Захистіть свій автомобіль від негоди за допомогою нашого передового керамічного покриття, що забезпечує довговічне глянцеве покриття, яке відштовхує бруд і підвищує зносостійкість.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://ua.codexafly.com/img/4.jpg"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="250px"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
							Оздоровлення салону
						</Text>
						<Text margin="8px 0px 0px 0px" color="--greyD2" font="300 18px/140% --fontFamily-sansHelvetica">
							- Глибоке очищення: Підвищіть естетику салону вашого автомобіля за допомогою наших послуг з глибокого очищення, забезпечивши чистоту та привабливість салону.
							<br />
							- Обробка шкіри: Побалуйте шкіряний салон вашого автомобіля нашими спеціалізованими засобами, зберігаючи його м'якість та розкішний вигляд.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://ua.codexafly.com/img/5.jpg"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="250px"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
							Фірмові пакети Code Xafly
						</Text>
						<Text margin="8px 0px 0px 0px" color="--greyD2" font="300 18px/140% --fontFamily-sansHelvetica">
							- Повний пакет робіт: Комплексна обробка інтер'єру та екстер'єру, завдяки якій ваш автомобіль буде блищати як зовні, так і всередині.
							<br />
							- Експрес-блиск: Ідеальний для швидкого оновлення, цей пакет зосереджується на деталях екстер'єру, надаючи вашому автомобілю миттєвого блиску.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://ua.codexafly.com/img/6.jpg"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="250px"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
							Спеціалізований догляд
						</Text>
						<Text margin="8px 0px 0px 0px" color="--greyD2" font="300 18px/140% --fontFamily-sansHelvetica">
							- Реставрація фар: Покращуйте видимість та естетику за допомогою нашої послуги з реставрації фар, щоб ваші фари виглядали як нові.
							<br />
							- Деталізація коліс та шин: Від видалення гальмівного пилу до кондиціонування шин - ми гарантуємо, що ваші колеса будуть виглядати бездоганно на дорозі.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://ua.codexafly.com/img/7.jpg"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="250px"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
							Індивідуальні плани технічного обслуговування
						</Text>
						<Text margin="8px 0px 0px 0px" color="--greyD2" font="300 18px/140% --fontFamily-sansHelvetica">
							- Планове технічне обслуговування: Обирайте наші плани планового технічного обслуговування, щоб ваш автомобіль завжди виглядав бездоганно завдяки регулярним сеансам деталізації, які відповідають вашим потребам.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://ua.codexafly.com/img/8.jpg"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="250px"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
							Відкрийте для себе відмінності Code Xafly
						</Text>
						<Text margin="8px 0px 0px 0px" color="--greyD2" font="300 18px/140% --fontFamily-sansHelvetica">
							- Майстерність за межами деталей: Code Xafly - це не просто деталізація - це свято автомобільної майстерності, де кожен автомобіль розглядається як унікальне полотно для самовираження.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box
				padding="80px 72px 80px 72px"
				background="#dddddd"
				border-radius="24px"
				margin="0px 0px 64px 0px"
				sm-padding="60px 36px 60px 36px"
			>
				<Box
					width="60%"
					display="flex"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--dark"
						md-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Як зв'язатися з нами
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--greyD3" md-text-align="center">
						Щоб дізнатися більше про наші послуги або запланувати подорож вашого автомобіля до блиску, зв'яжіться з нашою командою. Дозвольте Code Xafly стати куратором історії вашого автомобіля, розкриваючи блискучий спектр його справжнього потенціалу.
					</Text>
				</Box>
				<Link
					href="/contacts"
					color="#000000"
					padding="11px 45px 12px 45px"
					background="--color-primary"
					margin="50px 0px 0px 0px"
					display="inline-block"
					border-radius="8px"
				>
					{" "}Контакти
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});